
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.error {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 16px 0;

  @include for-size(phone-portrait-down) {
    background-color: $color-white;
    padding: 16px 8px;
  }

  .current-portal-logo {
    margin-bottom: 40px;

    svg {
      width: 50px;
      height: 50px;
      margin-right: 0;
    }
  }

  .esmp-link {
    font-size: inherit;
    text-decoration: underline;
    margin-left: 6px;
  }
}
